import request from '@/utils/request'
import requesthttps from '@/utils/requesthttps'
export function getQuestionBank(data){
    return request({
        url:'/get_question_bank',
        method:'post',
        data
    })
}
export function getZhangJie(data){
    return requesthttps({
        url:'/resourcePlant-web/api/console/school_catalog/queryListTree',
        method:'post',
        data
    })
}
// 保存作业
export function taskSave(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/plan/save',
    method: 'post',
    data
  })
}