<template>
	<div id="Nav_parent">
		<div id="Nav_class" class="Nav_class">
			<div class="Nav_class_top">
				<div class="">
					<div class="Nav_class_top_base">{{ $store.state.teachlanguagedata.classselection }}</div>
					<el-select clearable filterable v-model="selected" @change="QueryEditionListByClassId">
						<el-option v-for="option in options" :label="option.lable" :value="option.value">
							{{ option.lable }}
						</el-option>
					</el-select>
				</div>
				<div>
					<div class="Nav_class_top_base"> {{ $store.state.teachlanguagedata.subjectchoice }} </div>
					<el-select clearable filterable v-model="subject_data" @change="QueryEditionListByClassId">
						<el-option v-for="subject in subjects" :label="subject.name" :value="subject.id">
							{{ subject.name }}
						</el-option>
					</el-select>
				</div>
				<div v-if="Collectshow == '#/Collect' || UserStudybtn == 2">
					<div class="Nav_class_top_base"> {{ $store.state.teachlanguagedata.studentname }} </div>
					<el-select clearable filterable v-model="queryUserStudentId" @change="getstudent()">
						<el-option v-for="item in queryUserListStudent" :key="item.id" :label="item.name" :value="item.id">
							{{ item.name }}
						</el-option>
					</el-select>
				</div>
				<div v-if="UserStudybtn != 2">
					<div class="Nav_class_top_base"> {{ $store.state.teachlanguagedata.textbookcersion }} </div>
					<el-select clearable filterable v-model="EditionListIndex" @change="queryEditionListIndex">
						<el-option v-for="item in EditionList" :label="item.name" :value="item.id" :key="item.id" />
					</el-select>
				</div>
				<div v-if="UserStudybtn != 2">
					<div class="Nav_class_top_base"> {{ $store.state.teachlanguagedata.textbook }} </div>
					<el-select clearable filterable v-model="textbookId" @change="getzhangjie(textbookId)">
						<el-option v-for="jiaocai in jiaocais" :label="jiaocai.textbookName" :value="jiaocai.textbookId">
							{{ jiaocai.textbookName }}
						</el-option>
					</el-select>
				</div>
				<div v-if="UserStudybtn != 2" style="display:flex;align-items: center;justify-content: space-between;">
					<div class="Nav_class_top_base" style="margin-top: 24px;">
						{{ $store.state.teachlanguagedata.teachingchapters }} </div>
					<el-button style="font-size:20px;margin-top: 16px;" size="mini" type="text" @click="dialogVisible = true">
						+</el-button>
				</div>
			</div>
			<div class="Nav_class_con" v-if="UserStudybtn != 2">
				<div id="章节">
					<el-tree class="Nav_class_con_style" :data="data" :props="defaultProps" :show-checkbox="true"
						:default-expand-all="false" :highlight-current="true" :check-strictly="false" node-key="id"
						:default-checked-keys="chapters" :default-expanded-keys="expandedKeys" ref="tree"
						@check="handleCheckChange" :expand-on-click-node="false" :render-content="renderContent">
					</el-tree>
				</div>
			</div>
			<el-dialog :title="$store.state.teachlanguagedata.Modify_add_deletechapter" :visible.sync="dialogVisible"
				width="30%">
				<div v-if="dialogVisible">
					<div style="margin-bottom: 20px;">
						<span>{{ $store.state.teachlanguagedata.EnterNewName }}:</span>
						<el-input style="width:200px;margin-left:20px;" v-model="zhangjie.name"
							:placeholder="$store.state.teachlanguagedata.Deleteunusedinput"></el-input>
					</div>
					<span slot="footer" class="dialog-footer">
						<el-button @click="dialogVisible = false"> {{ $store.state.teachlanguagedata.cancel }}</el-button>
						<el-button type="primary"
							@click="catalogSave('删除')">{{ $store.state.teachlanguagedata.delete }}</el-button>
						<el-button type="primary"
							@click="catalogSave('修改')">{{ $store.state.teachlanguagedata.modify }}</el-button>
						<el-button type="primary"
							@click="catalogSave()">{{ $store.state.teachlanguagedata.confirm }}</el-button>
					</span>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import { mapMutations } from 'vuex'
import {
	getjiaoxueliebiao
} from '../api/class.js'
import {
	getTextBook,
	catalogsave,
	bindMatchRelation,
	queryMyClassList,
	queryEditionListByClassId
} from '../api/base.js'
import {
	queryUserListByClassId,
	queryAllErrorBookList
} from '../api/Collect.js'
import {
	getZhangJie
} from '../api/Source.js'
import { json } from 'body-parser'
import baseJs from '../utils/base/base.js'
export default {
	name: 'Class',
	data() {
		return {
			EditionListIndex: null,
			EditionList: null,
			UserStudybtn: 1,
			zhangjie: {},
			expandedKeys: [],
			dialogVisible: false,
			queryUserStudentId: '',
			queryUserListStudent: [],
			subject_data: null,//学科选择
			Collectshow: '',
			subjects: JSON.parse(localStorage.getItem("teachsubjects")),
			selected: null,//班级选择
			options: null,
			data: [],
			textbookId: '',
			jiaocais: [],
			chapters: localStorage.getItem("teachchapters") != 'undefined' ? JSON.parse(localStorage.getItem(
				"teachchapters")) : [],
			defaultProps: {
				children: 'children',
				label: 'label'
			}
		}
	},
	watch: {
		textbookId(val) {
			console.log(val, "val")
			if (!val) {
				this.chapters = []
				localStorage.setItem("teachchapters", JSON.stringify(this.chapters))
				this.$emit('child-chapters', this.chapters);
				this.$emit('child-source', this.chapters);
				this.windowparent()
			}
		},
		selected(val, oldVal) {
			localStorage.setItem("teachclass_id", JSON.stringify(val))
			// this.QueryEditionListByClassId()
		},
		chapters(val) {
			let canshu = this.findItemById(val, this.data)
			localStorage.setItem("teachchaptersData", JSON.stringify(canshu))
			if (this.$parent.initialization) {
				this.$parent.initialization(val)
			}
		},
		EditionListIndex(val) {
			localStorage.setItem("teachEditionListID", JSON.stringify(val))
			// this.getjiacai()
		}
	},
	created() { },
	mounted() {
		this.Collectshow = window.location.hash
		this.getbanji();
		if (window.location.hash == '#/Collect') {
			this.queryuserlistbyclassid()
		}
		this.c = document.getElementById('Nav_class')
		// body监听移动事件
		document.getElementById('clearfix').addEventListener('mousemove', this.move)
		// 鼠标按下事件
		this.c.addEventListener('mousedown', this.down)
		// 鼠标松开事件
		document.getElementById('clearfix').addEventListener('mouseup', this.up)
		// 是否开启尺寸修改
		this.resizeable = false
		// 鼠标按下时的坐标，并在修改尺寸时保存上一个鼠标的位置
		this.clientX = null
		this.clientY = null
		// div可修改的最小宽高
		this.minW = 8
		this.minH = 8
		// 鼠标按下时的位置，使用n、s、w、e表示
		this.direc = ''
	},
	methods: {
		// 鼠标松开时结束尺寸修改
		up() {
			this.resizeable = false
		},

		// 鼠标按下时开启尺寸修改
		down(e) {
			let d = this.getDirection(e)
			// 当位置为四个边和四个角时才开启尺寸修改
			if (d !== '') {
				this.resizeable = true
				this.direc = d
				this.clientX = e.clientX
				this.clientY = e.clientY
			}
		},

		// 鼠标移动事件
		move(e) {
			let d = this.getDirection(e)
			let cursor
			if (d === '') cursor = 'default';
			else cursor = d + '-resize';
			// 修改鼠标显示效果
			this.c.style.cursor = cursor;
			// 当开启尺寸修改时，鼠标移动会修改div尺寸
			if (this.resizeable) {
				// 鼠标按下的位置在左边，修改宽度
				if (this.direc.indexOf('e') !== -1) {
					this.c.style.width = Math.max(this.minW, this.c.offsetWidth + (e.clientX - this.clientX)) + 'px'
					this.clientX = e.clientX
				}
				if (this.direc.indexOf('w') !== -1) {
					this.c.style.width = Math.max(this.minW, this.c.offsetWidth + (this.clientX - e.clientX)) + 'px'
					this.clientX = e.clientX
				}

			}
		},

		// 获取鼠标所在div的位置
		getDirection(ev) {
			let xP, yP, offset, dir;
			dir = '';
			xP = ev.offsetX;
			yP = ev.offsetY;
			offset = 10;
			if (xP > this.c.offsetWidth - offset || this.c.offsetWidth + 50 > xP) dir += 'e';
			return dir;
		},
		UserStudyson(data) {
			this.UserStudybtn = data
			this.queryuserlistbyclassid()
		},
		renderContent(h, { node, data, store }) {
			return (
				<span class="custom-tree-node" >
					<el-popover placement="bottom" trigger="hover">
						<span> {node.label} </span>
						<span slot="reference">
							<span style="margin-left: 8px;white-space: nowrap;overflow: hidden;">{node.label}</span>
						</span>
					</el-popover>
					<span ><el-button style="margin-left:20px;font-size:20px;" size="mini" type="text" on-click={() => this.append(data)} > + </el-button> </span> </span>);
		},
		catalogSave(value) {
			if (value == '删除') {
				this.catalogdata.status = 0
				if (this.catalogdata.children && this.catalogdata.children.length > 0) {
					this.$message.error('只能从最低级删除');
					return
				}
				this.catalogdata.type = "chapter"
				this.catalogdata.textbookId = JSON.parse(localStorage.getItem('textbookId'))
				catalogsave(this.catalogdata).then((res1) => {
					this.dialogVisible = false
					let data = JSON.parse(localStorage.getItem('textbookId'))
					let textbook = JSON.parse(localStorage.getItem('textbooklist'))
					this.getzhangjie(data, textbook)
				})
			} else if (value == '修改') {
				this.catalogdata.name = this.zhangjie.name
				this.catalogdata.status = 1
				this.catalogdata.type = "chapter"
				this.catalogdata.textbookId = JSON.parse(localStorage.getItem('textbookId'))
				catalogsave(this.catalogdata).then((res1) => {
					this.dialogVisible = false
					let data = JSON.parse(localStorage.getItem('textbookId'))
					let textbook = JSON.parse(localStorage.getItem('textbooklist'))
					this.getzhangjie(data, textbook)
				})
			} else {
				let editionId;
				this.jiaocais.forEach((item) => {
					if (item.textbookId == this.textbookId) {
						editionId = item.editionId
					}
				})
				let data = {
					editionId,
					subjectId: this.subject_data,
					textbookId: this.textbookId,
					type: "chapter",
					name: this.zhangjie.name,
					level: this.catalogdata ? this.catalogdata.level + 1 : 1,
					parentId: this.catalogdata ? this.catalogdata.id : 0,
				}
				catalogsave(data).then((res1) => {
					this.dialogVisible = false
					let data1 = {
						editionId,
						subjectId: this.subject_data,
						textbookId: this.textbookId,
						type: "keyPoint",
						name: this.zhangjie.name,
						level: this.catalogdata ? this.catalogdata.level + 1 : 1,
						parentId: this.catalogdata ? this.catalogdata.id : 0,
					}
					catalogsave(data1).then((res2) => {
						let data2 = {
							"mainId": res1.data.result.id,
							"mainKind": "chapter",
							"relationId": res2.data.result.id,
							"relationKind": "keyPoint",
						}
						bindMatchRelation(data2).then(bindMatchRelationres => {
							let data = JSON.parse(localStorage.getItem('textbookId'))
							let textbook = JSON.parse(localStorage.getItem(
								'textbooklist'))
							this.getzhangjie(data, textbook)
						})
					})
				})
			}
		},
		append(data) {
			this.dialogVisible = true
			this.catalogdata = data
		},
		getstudent() {
			localStorage.setItem("teachstudentId", this.queryUserStudentId)
			this.queryUserListStudent.forEach((item) => {
				if (item.id == this.queryUserStudentId) {
					this.$emit("queryUserListStudentitem", item)
				}
			})
			this.$parent.queryallerrorbooklist ? this.$parent.queryallerrorbooklist(this.queryUserStudentId) :
				''
			this.$parent.queryuserDailyList ? this.$parent.queryuserDailyList(this.queryUserStudentId) : ''
		},
		queryuserlistbyclassid() {
			let data = {
				"currPage": 1,
				"id": JSON.parse(localStorage.getItem("teachclass_id")),
				"relationKind": "student",
				"pageSize": 100
			}
			queryUserListByClassId(data).then((res) => {
				this.queryUserListStudent = res.data.result.list
				this.queryUserStudentId = this.queryUserListStudent[0].id
				localStorage.setItem("teachstudentId", this.queryUserStudentId)
				this.$emit("queryUserListStudentitem", this.queryUserListStudent[0])
				this.$parent.queryuserDailyList ? this.$parent.queryuserDailyList() : ''
				this.$parent.queryallerrorbooklist ? this.$parent.queryallerrorbooklist(this
					.queryUserStudentId) : ''
			})
		},
		handleCheckChange(data, checked) {
			let canshuData = this.dataret(data, checked)
			let arrDeptId
			if (typeof canshuData === 'undefined') {
				arrDeptId = []
			} else {
				arrDeptId = [canshuData.id]
			}
			this.chapters = arrDeptId
			let n = arrDeptId[0]
			this.getArrDeptId(n)
			localStorage.setItem("teachchapters", JSON.stringify(this.chapters))
			this.$emit('child-chapters', this.chapters);
			this.$emit('child-source', this.chapters);
			this.windowparent()
		},
		dataret(data, checked) {
			let canshu;
			if (checked.checkedKeys.length > 0) {
				if (data.children && data.children.length > 0) {
					canshu = data.children[0]
					this.dataret(data.children[0], checked)
				} else {
					canshu = data
					this.$refs.tree.setCheckedKeys([data.id]);
				}
			}
			return canshu
		},
		windowparent() {
			if (window.location.hash == "#/Class" || window.location.hash == "#/class" || window.location
				.hash == "#/InClass" || window.location.hash == "#/inClass" || window.location.hash ==
				"#/AfterClass" || window.location.hash == "#/afterClass") {
				this.$parent.jobList();
			} else if (window.location.hash == "#/Collect" || window.location.hash == "#/collect") {
				this.$parent.queryrelationlist();
			} else if (window.location.hash == "#/Source" || window.location.hash == "#/source") {
				this.$parent.fatherMethodsource();
			} else if (window.location.hash == "#/Test" || window.location.hash == "#/test") {
				this.$parent.jobList();
			} else if (window.location.hash == "#/UserStudy" || window.location.hash == "#/userStudy") {
				this.$parent.queryclassPlanDetailList();
				this.$parent.queryuserDailyList();
			}
		},
		//选择版本
		queryEditionListIndex() {
			this.getjiacai()
		},
		//获取教材
		getjiacai() {
			localStorage.setItem("teachsubjectId", JSON.parse(this.subject_data))
			getTextBook({
				"classId": this.selected,
				'subjectId': this.subject_data,
				editionId: this.EditionListIndex
			}).then(response => {
				this.jiaocais = response.data.result
				if (response.data.result[0].textbookId) {
					this.textbookId = response.data.result[0].textbookId
					this.getzhangjie(this.textbookId, response.data.result[0])
				} else {
					this.textbookId = null
					this.$message({
						message: "教材为空  请检查该账号学科是否完成绑定",
						type: 'warning',
						duration: 3000
					})
					this.data = []
					localStorage.removeItem("teachchapters")
					this.$emit('child-tableData', []);
					this.$emit('child2-tableData', []);
					return
				}
				// if (localStorage.getItem("textbookId") != undefined) {
				// 	if (JSON.stringify(this.jiaocais).indexOf(localStorage.getItem(
				// 			"textbookId")) === -1) {
				// 		if (response.data.result[0]) {
				// 			this.textbookId = response.data.result[0].textbookId;
				// 		} else {
				// 			this.textbookId = ''
				// 			this.data = []
				// 			localStorage.removeItem("teachchapters")
				// 			this.$emit('child-tableData', []);
				// 			this.$emit('child2-tableData', []);
				// 			return
				// 		}
				// 	} else {
				// 		this.textbookId = JSON.parse(localStorage.getItem("textbookId"))
				// 	}
				// } else {
				// 	this.textbookId = response.data.result[0].textbookId;
				// }
				console.log("getzhangjie")

			})
		},
		recursion(val) {
			if (Object.prototype.toString.call(val.children) === '[object Array]' && val.children.length > 0) {
				return this.recursion(val.children[0]);
			} else {
				return val.id
			}
		},
		// attributeName 属性值名称 attribute属性值 array 数组
		arrayReturnObject(attributeName, attribute, array) {
			let results = array.filter(item => {
				return item[attributeName] == attribute
			})
			return results[0] ? results[0] : ''
		},
		findItemById(id, list) {
			let res = list.find(item => item.id == id)
			if (res) {
				return res
			} else {
				for (let i = 0; i < list.length; i++) {
					if (list[i].children instanceof Array && list[i].children.length > 0) {
						res = this.findItemById(id, list[i].children)
						if (res) {
							return res
						}
					}
				}
				return null
			}
		},
		//获取章节
		getzhangjie(data, textbook) {
			localStorage.setItem("textbookId", JSON.stringify(data))
			if (textbook) {
				localStorage.setItem("textbooklist", JSON.stringify(textbook))
			} else {
				this.arrayReturnObject('textbookId', data, this.jiaocais)
				let textbooks = this.arrayReturnObject('textbookId', data, this.jiaocais)
				localStorage.setItem("textbooklist", JSON.stringify(textbooks))
			}
			getZhangJie({
				"type": "chapter",
				'textbookId': data
			}).then(response => {
				let val = response.data.result[0]
				if (localStorage.getItem("teachchapters") == undefined) {
					this.chapters = []
				} else {
					let indexjudge = JSON.parse(localStorage.getItem("teachchapters"))[JSON.parse(
						localStorage
							.getItem("teachchapters")).length - 1]
					if (JSON.stringify(response.data.result).indexOf(JSON.stringify(indexjudge)) === -
						1) {
						this.chapters = []
					} else {
						this.chapters = JSON.parse(localStorage.getItem("teachchapters"))
					}
				}
				localStorage.setItem("teachchapters", JSON.stringify(this.chapters))
				this.expandedKeys = baseJs.orgChildIdFindParent([this.chapters], response.data.result)
				this.$emit('child-chapters', this.chapters);
				this.$emit('child-source', this.chapters);
				this.windowparent()
				this.data = response.data.result
			})
		},
		QueryEditionListByClassId() {
			let data = {
				"classId": this.selected,
				'subjectId': this.subject_data,
			}
			queryEditionListByClassId(data).then(res => {
				//获取教材版本
				this.EditionList = res.data.result
				if (this.EditionList && this.EditionList.length > 0) {
					this.EditionListIndex = this.EditionList[0].id
					// if(localStorage.getItem('teachEditionListID')){
					// 	let canshu=this.EditionList.some(animal => animal.id === JSON.parse(localStorage.getItem('teachEditionListID')))
					// 	if(!canshu){
					// 		this.EditionListIndex=this.EditionList[0].id
					// 	}else{
					// 		this.EditionList.forEach(item=>{
					// 			if(item.id==JSON.parse(localStorage.getItem('teachEditionListID'))){
					// 				this.EditionListIndex=item.id
					// 			}
					// 		})
					// 	}
					// }else{
					// 	this.EditionListIndex=this.EditionList[0].id
					this.getjiacai()
					// }
				} else {
					this.EditionListIndex = null;
					this.$message({
						message: "教材版本为空  请检查该账号学科是否完成绑定",
						type: 'warning',
						duration: 3000
					})

				}
			})
		},
		//获取班级
		getbanji() {
			let data = {
				'userToken': JSON.parse(localStorage.getItem("teachuserToken")),
			}
			queryMyClassList(data).then(response => {
				response.data.result.forEach(item => {
					item.value = item.id
					item.lable = item.name
				})
				this.options = response.data.result
				this.selected = localStorage.getItem("teachclass_id") != undefined ? JSON.parse(
					localStorage
						.getItem("teachclass_id")) : this.options[0].value
				localStorage.setItem("teachclass_id", JSON.stringify(this.selected))
				//学科是登录账号就给了的
				this.subject_data = localStorage.getItem("teachsubjectId") != undefined ? JSON.parse(
					localStorage.getItem("teachsubjectId")) : JSON.parse(localStorage.getItem(
						"teachsubjects"))[0].id
				this.QueryEditionListByClassId()
			})
		},
		...mapMutations(['getArrDeptId'])
	}
}
</script>

<style>
.Nav_class_top_student {
	margin-bottom: 10px;
}

.Nav_class {
	height: 90vh;
	overflow: auto;
	text-align: left;
	border-right: 1px solid #D8DEEA;
}

.Nav_class_top {
	padding: 20px 20px 0px 20px;
}

.Nav_class_con {
	border-top: 1px solid #D8DEEA;
	overflow: auto;
	padding: 20px;
}

.Nav_class_top_base {
	font-size: 14px;
	color: #071737;
	margin: 8px 0;
	font-weight: 550;
}</style>
